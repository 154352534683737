<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info
                :info="infodata"
                v-if="system == 'system1'"
            ></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">{{ title }}</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="数据密级" prop="sjmj">
                    <el-select
                        v-model="formdata.saveJson.sjmj"
                        placeholder="请选择数据密级"
                        :disabled="!isEdit"
                        @change="changeSJMJ"
                    >
                        <el-option label="公开" value="公开"></el-option>
                        <el-option label="不公开" value="不公开"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-show="formdata.saveJson.sjmj === '公开'"
                    label="发布范围"
                    prop="fbfw"
                >
                    <el-select
                        v-model="formdata.saveJson.fbfw"
                        placeholder="请选择发布范围"
                        :disabled="!isEdit"
                    >
                        <el-option
                            label="互联网公开"
                            value="互联网公开"
                        ></el-option>
                        <el-option
                            label="行业内部公开"
                            value="行业内部公开"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类别">
                    <el-select
                        v-if="system == 'system1'"
                        v-model="formdata.saveJson.lb"
                        filterable
                        clearable
                        placeholder="请选择类别"
                        :disabled="!isEdit"
                        @change="changeLB()"
                    >
                        <el-option
                            v-for="(item, index) in typeList"
                            :key="index"
                            :label="item.LABEL"
                            :value="item.CODE"
                        ></el-option>
                    </el-select>

                    <el-select
                        v-if="system == 'system2'"
                        v-model="formdata.saveJson.lb"
                        filterable
                        clearable
                        placeholder="请选择类别"
                        :disabled="true"
                        @change="changeLB()"
                    >
                        <el-option
                            v-for="(item, index) in typeList"
                            :key="index"
                            :label="item.LABEL"
                            :value="item.CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item
                    label="保护管理机构内部制度类别"
                    v-if="formdata.saveJson.lb === '3'"
                    prop="bhgljgnbzdlb"
                >
                    <el-select
                        v-model="formdata.saveJson.bhgljgnbzdlb"
                        filterable
                        clearable
                        placeholder="请选择保护管理机构内部制度类别"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in bhgljgnbzdlbList"
                            :key="index"
                            :label="item.LABEL"
                            :value="item.CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-if="formdata.saveJson.lb === '4'"
                    label="其他类别"
                    prop="qt"
                >
                    <el-tooltip
                        effect="light"
                        content="请据实填写其他类别"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.qt"
                            :maxlength="50"
                            placeholder="请输入其他类别"
                            :disabled="!isEdit"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="名称" prop="mc">
                    <el-tooltip
                        effect="light"
                        content="填写本遗产地专项保护管理法规的名称"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.mc"
                            :maxlength="50"
                            placeholder="请输入名称"
                            :disabled="!isEdit"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="公布时间">
                    <el-date-picker
                        v-model="formdata.saveJson.gbsj"
                        clearable
                        value-format="yyyy-MM"
                        type="month"
                        placeholder="请选择公布时间"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="公布单位">
                    <el-tooltip
                        effect="light"
                        content="填写本遗产地专项保护管理法规的公布单位"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.gbdw"
                            :maxlength="50"
                            placeholder="请输入公布单位"
                            :disabled="!isEdit"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="公布文号">
                    <el-tooltip
                        effect="light"
                        content="填写本遗产地专项保护管理法规的公布文号"
                        placement="bottom"
                    >
                        <el-input
                            v-model="formdata.saveJson.gbwh"
                            :maxlength="50"
                            placeholder="请输入公布文号"
                            :disabled="!isEdit"
                        ></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="实施时间">
                    <el-date-picker
                        v-model="formdata.saveJson.sssj"
                        clearable
                        value-format="yyyy-MM"
                        type="month"
                        placeholder="请选择实施时间"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="状态" prop="zt">
                    <el-select
                        v-model="formdata.saveJson.zt"
                        filterable
                        clearable
                        placeholder="请选择状态"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in ztList"
                            :key="index"
                            :label="item.LABEL"
                            :value="item.CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <basic-upload
                    v-if="formdata.saveJson.sjmj === '公开'"
                    ref="uploadFile"
                    :itemId="itemId"
                    :metaData.sync="formdata.metaJson"
                    :basicType="basicType"
                    :isEdit="isEdit"
                >
                </basic-upload>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import alertInfo from "@comp/alert_info";
import basicUpload from "@comp/basic_upload";
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "special_regulations",
    components: {
        alertInfo,
        basicUpload,
    },
    mixins: [infoMixin],
    data() {
        return {
            title: "",
            snButton: snButton,
            rules: {
                sjmj: [
                    {
                        required: true,
                        message: "请选择数据密级",
                        trigger: "blur",
                    },
                ],
                fbfw: [
                    {
                        required: true,
                        message: "请选择发布范围",
                        trigger: "blur",
                    },
                ],
                mc: [
                    { required: true, message: "请输入名称", trigger: "blur" },
                ],
                zt: [
                    {
                        required: true,
                        message: "请选择状态",
                        trigger: "change",
                    },
                ],
                qt: [
                    {
                        required: true,
                        message: "请选择其他类别",
                        trigger: "change",
                    },
                ],
                bhgljgnbzdlb: [
                    {
                        required: true,
                        message: "请选择保护管理机构内部制度类别",
                        trigger: "change",
                    },
                ],
            },
            formdata: {
                saveJson: {
                    sjmj: "公开",
                    fbfw: "行业内部公开",
                    bhgljgnbzdlb: "", //保护管理机构内部制度类别
                    lb: "",
                    qt: "",
                    mc: "",
                    gbsj: "",
                    gbdw: "",
                    gbwh: "",
                    sssj: "",
                    zt: "",
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "22",
                userName: "",
                userId: "",
            },
            typeList: [], //类别
            bhgljgnbzdlbList: [], //保护管理机构内部制度类别 列表
            ztList: [], //状态
        };
    },
    computed: {
        ...mapGetters(["system"]),
    },
    created() {
        this.GetEnum();
    },
    mounted() {
        switch (this.system) {
            case "system1":
                this.title = "专项保护管理法规、规章信息";
                break;
            case "system2":
                this.title = "消防制度";

                break;
        }
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "22",
            });
            this.typeList = res[1].dataItems;
            this.bhgljgnbzdlbList = res[0].dataItems;
            this.ztList = res[2].dataItems;
            if (this.system == "system2") {
                this.formdata.saveJson.lb = "3";
            }
        },
        changeLB() {
            let value = this.formdata.saveJson.lb;
            if (value !== "4") {
                this.formdata.saveJson.qt = "";
            }
            if (value !== "3") {
                this.formdata.saveJson.bhgljgnbzdlb = "";
            }
        },
        // 配置数据
        getDataNext() {
            if (
                this.formdata.saveJson.bhgljgnbzdlb ||
                this.formdata.saveJson.bhgljgnbzdlb == 0
            ) {
                this.formdata.saveJson.bhgljgnbzdlb = String(
                    this.formdata.saveJson.bhgljgnbzdlb
                );
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
